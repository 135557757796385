@import './variables.scss';

.success_page{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .container{
        width: 60%;
        margin: auto;
        font-family: $font-family !important;
        text-align: center;

        @media screen and (max-width:$md-breakpoint){
            width: 95%;
        }

        img{
            height: 250px;
        }
    }

    .footer{
        width: 100%;
    }
    // ?chasisNumber=1234&policyNo=bomboclat&certificationNo=6783839&invoiceNumber=9073682

}