

.ir{
    margin-left: 5px;
    color : #fff;
}

.btn_request{
    // width: 100%;
    height: 36px !important;
    display: flex;
    align-items: center;
    justify-content: right;
    margin-left: auto;
    margin-top: 30px;

    img{
        width: 30px;
        height: 30px;
    }
}