@import '../../styles/variables.scss';

.requests__table {
  border-collapse: collapse;
    border-spacing: 0 8px;
    font-size: 1rem;
    width: 100%;
    height: 100%;
    padding:0;

    @media screen and (max-width :$md-breakpoint){
      font-size: .8rem;
    }
    tr {
      display: table-row;
    }
    td{
      height: auto;
      padding : 10px;
    }
    td,
    th {
      border: none;
      display:table-cell;
      vertical-align: top;
      font-size: .95em;
    }
    th{
      padding: 10px;
    }
    thead {
      background-color: rgb(51, 51, 51);
      color: #ffffff;
      min-width:100%;
      margin:0;
      padding: 0;
      white-space: nowrap;
      text-align: center;
    }
    tbody tr {
      background-color: #fff;
      border-bottom: 1px solid rgb(182, 182, 182);
    }

    .tel_input {
      height: 52px !important;
      font-family: var(--Inter-regular) !important;
      font-size: 18px !important;
    }

    .tel_input input {
      height: 100% !important;
      max-width: 310px !important;
      width: 100% !important;
      border: 1px solid darkgrey !important;
    }
  }
.parami{
  margin:0; 
  overflow-wrap: break-word; 
  font-weight:500; 
  letter-spacing:1px; 
  width:200px;
  font-size: .9em;
  line-height: 1.5em;
  @media only screen and (min-width: 700px){
    width: 100%;
  }
}
.mt{
  margin:0;
}
