@import './variables.scss';

.index_page{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    .container {
        width: 95%;
        margin: auto;
        font-family: $font-family !important;

    
        @media (min-width: $md-breakpoint) {
            width: 100%;
        }
    
        .header{
            width:100%;
            padding: 20px 0 12px;
            text-align:center;
    
            h2{
                font-size: 1.5em;
                margin:0; 
            }
        }
    
        .insImage{
            img{
                height: 150px;
            }
        };
    
        .description{
            text-align: center;
            padding-bottom: 15px;
    
            p{
                margin:0;
            }
        }
    
        .loader_container{
            width:100%;
            text-align: center;
            display: flex;
            justify-content: center;
        }
    
        @media (min-width: $lg-breakpoint) {
            width: 800px;
    
            .header{
    
                h2{
                    font-size: 3.5em; 
                }
    
            }
        }
    
        .response_container{
            padding: 15px 0;
            text-align: center;
        }
    }

    .footer{
        width: 100%;
    }
}