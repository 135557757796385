@import '../../styles/variables.scss';

.formstyle{

    width: 100%;
    margin: 0 auto;

    .input_container{
        width: 100%;
        position: relative;
        margin-bottom: 10px;

        .error_text{
            font-size: 10px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 2px;
            color: #FF5592;
        }
    }

    .searchicon{
        
        position: absolute;
        top:0;
        left:0;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 12px;
        padding-top: 4px;
        opacity: .6;

        img{
            width: 20px;
            height: 20px;
        }
    }

    .input_style{
        
        input{
            padding-left: 45px;
        }

        label{
            padding-left: 45px;
        }

    }

    .button_container{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn_space{
        width: 15px;
    }

    .search_status{
        height:12px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        p{
            margin:0;
            font-size: .85em;
        }

        .success{
            color:#64FDE1;
        }

        .failure{
            color: #FF5592;
        }
    }

    .clear_search_btn{
        background: none;
        border: 1px solid #FF5592;
        color: #FF5592;
        box-shadow: none;

        &:hover {
            background: none
        }
    }

    @media (min-width: $md-breakpoint) {
        width: 500px;
    }

}