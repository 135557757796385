
.result_not_found{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding:30px 0;
    opacity:.5;

    p{  
        margin: 0;
        margin-right: 7px;
    }

    img{
        width: 15px;
        height: 15px;
    }
};

.buy_insurance {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    text-align: center;

    img{
        height: 200px;
    }

    p{
        font-size: 10px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 2px;
        color : rgb(79, 79, 79);
    }
}